import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
import AdminRoutes from "./AdminRoutes";
import MasterAdminRoutes from "./MasterAdminRoutes";
import { useSelector, useDispatch } from "react-redux";
import {
  logoutUserAsync,
  refreshUserAsync,
  UpdateSessionAlert,
} from "../redux/authSlice";
import axiosInstance from "../helper/axiosInstance";
import Loader from "../components/Loader"; // Import Loader component

export default function Routes() {
  const dispatch = useDispatch();
  const { userType, userDetails, loading, showUnauthorizedModal } = useSelector(
    (state) => state.auth
  );

  // useEffect(() => {
  //   const requestInterceptor = axiosInstance.interceptors.response.use(
  //     (response) => {
  //       console.log("API Response received successfully:", response.data.token);
  //       return response;
  //     },
  //     async (error) => {
  //       if (error?.response?.status === 401 && error?.response?.data?.error === "Unauthorized") {
  //         console.error("Unauthorized Error in response:", error?.response?.data?.error);
  
  //         const userLoggedToken = sessionStorage.getItem("userLoggedToken");
  //         if (userLoggedToken) {
  //           console.log("User token exists in session storage");
  //           dispatch(UpdateSessionAlert(true));
  //         } else {
  //           console.log("User token does not exist in session storage");
  //         }
  //       } else {
  //         console.error("Error in response:", error?.response?.data?.error || error.message);
  //       }
  //       return Promise.reject(error);
  //     }
  //   );
  //   return () => {
  //     axiosInstance.interceptors.response.eject(requestInterceptor);
  //   };
  // }, [dispatch]);
  

  const handleLogout = async () => {
    if (userDetails?.id) {
      await dispatch(logoutUserAsync(userDetails?.id));
      sessionStorage.clear();
      dispatch(UpdateSessionAlert(false));
    }
  };

  const handleRefresh = async () => {
    if (userDetails?.id) {
      const data = {
        id: userDetails?.id,
        accountType: userDetails.accountType,
      };

      try {
        await dispatch(refreshUserAsync(data)); 
        dispatch(UpdateSessionAlert(false)); 
        if(data.accountType === 'masteradmin'){
          window.location.reload();
        }
      } catch (error) {
        console.error("Error refreshing session:", error);
      }
    }
  };

  // useEffect(() => {
  //   // const handleLogout = async () => {
  //   //   if (userDetails?.id) {
  //   //     dispatch(logoutUserAsync(userDetails?.id));
  //   //   }
  //   // };

  //   // const handleBeforeUnload = (event) => {
  //   //   handleLogout();
  //   //   event.preventDefault();
  //   //   event.returnValue = "";
  //   // };

  //   // window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     // window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const renderRoutes = () => {
    switch (userType) {
      case "UNREGISTERED":
        return <AuthRoutes />;
      case "admin":
        return <AdminRoutes />;
      case "masteradmin":
        return <MasterAdminRoutes />;
      default:
        return null;
    }
  };

  const modalStyles = {
    modal: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
    modalContent: {
      background: "white",
      padding: "20px",
      borderRadius: "8px",
      textAlign: "center",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
      maxWidth: "400px",
      width: "100%",
    },
    modalButtons: {
      marginTop: "20px",
    },
    button: {
      margin: "0 10px",
      padding: "10px 20px",
      backgroundColor: "#007bff",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "16px",
    },
  };

  return (
    <Router>
      {renderRoutes()}

    </Router>
  );
}
