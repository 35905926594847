import React, { useState, useEffect } from "react";
import moment from "moment";
import "./NewMessage.scss";
import Blur from "react-css-blur";

import { AddFavList } from "../../../redux/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import Socket from "../../../redux/socketConnect";
import ClassNameGenerator from "@mui/utils/ClassNameGenerator";
import { formatDate, formatDateMsg } from "../../Utils/formatDate";
import { CompassOutlined } from "@ant-design/icons";

const NewMessage = ({
  recentMessages,
  conversation,
  currentUser,
  handleClick,
  receiver,
  unreadMessageCounts,
  showUnreadAndUnanswered,
  RemoveRoom,
  isRemoved,
  ChatData,
  isNewMessage
}) => {
  const dispatch = useDispatch();
  const {
    RecieverDetails,
    AllUsers,
    notification,
    messages,
    ChatRoomsUsers,
    currentChat,
    unreadMsg,
    favList,
    usersNotFoundMessage,
  } = useSelector((state) => state.chat);

  const [notificationData, setNotificationData] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState([]);
  const [Favusers, setFavusers] = useState([]);
  const [selectedUserMessages, setSelectedUserMessages] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [latestMessageTimestamps, setLatestMessageTimestamps] = useState({});
  const [displayChatUsers, setDisplayChatUsers] = useState([]);
  const [blurredChats, setBlurredChats] = useState([]);

  useEffect(() => {
    const handleRemoveUserFromChat = (data) => {
      setBlurredChats((prev) => [...prev, data?.isSameChatRoom?.senderId]);
    };
    console.log('chatdata Ouside.......',  ChatData, isNewMessage);

    if (isRemoved && RemoveRoom) {
      handleRemoveUserFromChat(RemoveRoom);
    } else {
      console.log('Removing inside',isRemoved,RemoveRoom);
      setBlurredChats([]);
    }
  }, [isRemoved,RemoveRoom]);

  const loadSelectedUserMessages = (userId, lastMsg) => {
    if (!lastMsg) {
      const userMessages = recentMessages?.filter(
        (message) =>
          message.senderId === userId || message.receiverId === userId
      );
      setSelectedUserMessages(userMessages);
      setSelectedUserId(userId);
    }
  };

  useEffect(() => {
    const unReadChats = recentMessages.filter((m) => m.isRead !== true);
    let isOpen = unReadChats.filter((m) => m?.chatId !== ChatRoomsUsers?.id);
    if (isOpen) {
      setIsChatOpen(isOpen);
      setNotificationData((prevData) => [...prevData, ...isOpen]);
    } else {
      setNotificationData([]);
    }
    return () => setIsChatOpen(null);
  }, [unreadMsg]);

  const toggleFav = (user) => {
    const updatedFavList = favList?.some((favUser) => favUser.id === user.id)
      ? favList?.filter((favUser) => favUser.id !== user.id)
      : [...favList, user];
    dispatch(AddFavList(updatedFavList));
  };

  const sortUsers = (users, favList) => {
    const sortedUsers = [...users];
    sortedUsers.sort((user1, user2) => {
      const isFav = (user, favList) =>
        favList?.some(fav => fav?.id === user?.id);
  
      const user1IsFav = isFav(user1, favList);
      const user2IsFav = isFav(user2, favList);
  
      if (user1IsFav && !user2IsFav) return -1;
      if (!user1IsFav && user2IsFav) return 1;
  
      // Compare using the enhanced lastChatTime
      const timestamp1 = user1?.lastChatTime || 0;
      const timestamp2 = user2?.lastChatTime || 0;
  
      return timestamp2 - timestamp1;
    });
  
    return sortedUsers;
  };

  const handleOpenChat = (conversation) => {
    handleClick(conversation);
    if (conversation?.id === isChatOpen[0]?.senderId) {
      setIsChatOpen(false);
      setNotificationData([]);
    }
  };

  useEffect(() => {
    Socket.socketInit(currentUser?.id, dispatch);
  }, [dispatch]);

  // // Update displayChatUsers based on showUnreadAndUnanswered and sortUsers
  // useEffect(() => {
  //   const sortedRecieverDetails = sortUsers(receiver, favList);

  //   if (showUnreadAndUnanswered) {
  //     const filteredReceiver = sortedRecieverDetails?.filter(
  //       (user) => user?.isSenderAdmin === false && user?.lastMessage
  //     );
  //     setDisplayChatUsers(filteredReceiver || []);
  //   } else {
  //     setDisplayChatUsers(sortedRecieverDetails);
  //   }
  // }, [
  //   isNewMessage,
  //   showUnreadAndUnanswered,
  //   recentMessages,
  //   receiver,
  //   favList,
  //   displayChatUsers,
  // ]);

  useEffect(() => {
    const updatedDisplayChatUsers = displayChatUsers?.map((user) => {
      if (user.id === ChatData?.chatUser?.id) {
        return {
          ...user,
          chatId: ChatData?.chatId,
        };
      }
      return user;
    });
    setDisplayChatUsers(updatedDisplayChatUsers);
  }, [ChatData, isNewMessage]);




  useEffect(() => {
    const enhancedUsers = receiver.map(user => {
      // Find all messages involving this user
      const userMessages = recentMessages.filter(
        msg => msg.senderId === user.id || msg.receiverId === user.id
      );
      
      // Get the latest message timestamp
      const lastMessage = userMessages[userMessages.length - 1];
      return {
        ...user,
        // Use timestamp from recentMessages if available
        lastChatTime: lastMessage?.createdAt || user.lastChatTime
      };
    });
  
    const sortedRecieverDetails = sortUsers(enhancedUsers, favList);
  
    if (showUnreadAndUnanswered) {
      const filteredReceiver = sortedRecieverDetails?.filter(
        user => user?.isSenderAdmin === false && user?.lastMessage
      );
      setDisplayChatUsers(filteredReceiver || []);
    } else {
      setDisplayChatUsers(sortedRecieverDetails);
    }
  }, [showUnreadAndUnanswered, recentMessages, receiver, favList]); 

  return (
    <div className="msgContainer">
      {recentMessages && displayChatUsers?.length > 0 ? (
        displayChatUsers?.map((user, i) => {
          const userMessages = recentMessages?.filter(
            (message) =>
              message.senderId === user.id || message.receiverId === user.id
          );

          let lastMsg;
          let IslastMsgRead;
          let lastMsgReadBy;
          let lastChatTime = "";

          if (userMessages?.length > 0) {
            const lastMessage = userMessages[userMessages?.length - 1];
            const lastChatMsg = lastMessage.text;

            lastMsgReadBy =
              user?.LastMsgReadBy && user?.LastMsgReadBy !== currentUser.id
                ? true
                : false;

            IslastMsgRead =
              lastMessage.senderId !== currentUser?.id
                ? lastMessage.isLastMsgRead
                : false;
            const maxMessageLength = 20;
            lastMsg =
              lastChatMsg?.length > maxMessageLength
                ? lastChatMsg?.substring(0, maxMessageLength - 3) + "..."
                : lastChatMsg;

            const timestamp = parseInt(lastMessage?.createdAt, 10);
            lastChatTime = formatDateMsg(timestamp);
          }

          let lastMsgdb;
          let isLastMsgdbRead;
          let lastMsgReadByDb;
          const maxdbMessageLength = 20;
          if (user?.lastMessage) {
            lastMsgdb =
              user?.lastMessage?.length > maxdbMessageLength
                ? user?.lastMessage?.substring(0, maxdbMessageLength - 3) +
                "..."
                : user?.lastMessage;
            isLastMsgdbRead = user?.isLastMsgRead;
            lastMsgReadByDb =
              user?.LastMsgReadBy && user?.LastMsgReadBy !== currentUser.id
                ? true
                : false;
          }
          // console.log("lastMsgReadByDb", user.isLastMsgRead);

          let nameChar =
            user.accountType == "business"
              ? user?.userId?.charAt(0).toUpperCase()
              : user?.userId?.charAt(0).toUpperCase();

          const timestamp = parseInt(user?.lastChatTime, 10);
          let lastChatTimeDB = formatDateMsg(timestamp);

          const unreadCount = recentMessages.filter(
            (msg) => msg?.senderId === user?.id && !msg.isRead
          ).length;

          let isChatBlurred = blurredChats.includes(user.id);
      
          return (
            <div
              className={`CustomerMessages ${isChatBlurred || lastMsgReadBy || lastMsgReadByDb
                  ? "setbusy"
                  : "setfree"
                } ${currentChat === user.id ? "active" : ""}`}
              onClick={() => {
                handleOpenChat(user);
                loadSelectedUserMessages(user?.id, lastMsg);
              }}
              key={i}
            >
              <div className="divOne">
                {nameChar == undefined ? "NA" : nameChar}
              </div>
              <div className="divTwo">
                <div className="upperDiv">
                  <h6 style={{ fontFamily: "Roboto" }}>
                    {user?.userId || user?.id?.slice(0, 8)}
                    {user?.isSessionExpired == false &&
                      currentUser?.id !== user?.connectedTo && (
                        <span style={{ color: "red" }}>(busy)</span>
                      )}
                  </h6>
                  {/* Add !isChatBlurred to the condition */}
                   { (lastChatTime || lastChatTimeDB) && (
                    <p>{lastChatTime || lastChatTimeDB}</p>
                  )}
                </div>
                <div className="lowerDIv">
                  <Blur
                    radius={
                      isChatBlurred || lastMsgReadBy || lastMsgReadByDb
                        ? "5px"
                        : "0"
                    }
                    transition="400ms"
                  >
                    <p>{lastMsg || lastMsgdb}</p>
                  </Blur>
                  {currentChat !== user?.id &&
                    (unreadCount > 0 || user?.UnReadMsgCount > 0) && (
                      <div
                        className={`msgCount ${
                          isChatBlurred || user.isLastMsgRead ? "BlurCount" : ""
                        } `}
                      >
                        <div className="msgRec">
                          {unreadCount || user?.UnReadMsgCount}
                        </div>
                      </div>
                    )}

                  <div>
                    {
                      <img
                        src={
                          favList?.includes(user)
                            ? require("../../../assets/icons/StarFilled.svg")
                                .default
                            : require("../../../assets/icons/StartEmpty.svg")
                                .default
                        }
                        alt="user"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFav(user);
                        }}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "25%",
          }}
        >
          <span
            style={{
              fontSize: "1rem",
              fontFamily: "Segoe UI",
              color: "#717171",
            }}
          >
            {showUnreadAndUnanswered
              ? "Ta-da! You’re up to date."
              : usersNotFoundMessage}
          </span>
        </div>
      )}
    </div>
  );
};

export default NewMessage;
