import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Blur from "react-css-blur";
import "./NewMessage.scss";
import { AddFavList } from "../../../redux/chatSlice";
import Socket from "../../../redux/socketConnect";
import { formatDateMsg } from "../../Utils/formatDate";

const FavoritesUsers = ({
  recentMessages,
  conversation,
  currentUser,
  handleClick,
  receiver,
  unreadMessageCounts,
  isFav,
  RemoveRoom,
  isRemoved,
}) => {
  const dispatch = useDispatch();
  const {
    ChatRoomsUsers,
    currentChat,
    unreadMsg,
    favList,
  } = useSelector((state) => state.chat);

  const [notificationData, setNotificationData] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState([]);
  const [selectedUserMessages, setSelectedUserMessages] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [blurredChats, setBlurredChats] = useState([]);

  useEffect(() => {
    const handleRemoveUserFromChat = (data) => {
      setBlurredChats((prev) => [...prev, data?.isSameChatRoom?.senderId]);
    };

    if (isRemoved && RemoveRoom) {
      handleRemoveUserFromChat(RemoveRoom);
    } else {
      setBlurredChats([]);
    }
  }, [isRemoved, RemoveRoom]);

  const loadSelectedUserMessages = (userId, lastMsg) => {
    if (!lastMsg) {
      const userMessages = recentMessages?.filter(
        (message) =>
          message.senderId === userId || message.receiverId === userId
      );
      setSelectedUserMessages(userMessages);
      setSelectedUserId(userId);
    }
  };

  useEffect(() => {
    const unReadChats = recentMessages.filter((m) => m.isRead !== true);
    let isOpen = unReadChats.filter((m) => m?.chatId !== ChatRoomsUsers?.id);

    if (isOpen) {
      setIsChatOpen(isOpen);
      setNotificationData((prevData) => [...prevData, ...isOpen]);
    } else {
      setNotificationData([]);
    }
  }, [unreadMsg]);

  const toggleFav = (user) => {
    const updatedFavList = favList?.some((favUser) => favUser.id === user.id)
      ? favList?.filter((favUser) => favUser.id !== user.id)
      : [...favList, user];
    dispatch(AddFavList(updatedFavList));
  };

  const handleOpenChat = (conversation) => {
    handleClick(conversation);
    if (conversation?.id === isChatOpen[0]?.senderId) {
      setIsChatOpen(false);
      setNotificationData([]);
    }
  };

  return (
    <div className="msgContainer">
      {recentMessages &&
        favList?.map((user, i) => {
          const userMessages = recentMessages?.filter(
            (message) =>
              message.senderId === user.id || message.receiverId === user.id
          );

          let lastMsg;
          let lastMsgReadBy;
          let lastChatTime = "";
          let lastMsgdb;
          let lastMsgReadByDb;
          let lastChatTimeDB = "";

          if (userMessages?.length > 0) {
            const lastMessage = userMessages[userMessages?.length - 1];
            const maxMessageLength = 40;
            lastMsg =
              lastMessage?.text?.length > maxMessageLength
                ? lastMessage?.text?.substring(0, maxMessageLength - 3) + "..."
                : lastMessage.text;
            
            lastMsgReadBy =
              user?.LastMsgReadBy && user?.LastMsgReadBy !== currentUser.id
                ? true
                : false;

            const timestamp = parseInt(lastMessage.createdAt, 10);
            lastChatTime = formatDateMsg(timestamp);
          }

          const maxdbMessageLength = 40;
          if (user?.lastMessage) {
            lastMsgdb =
              user?.lastMessage?.length > maxdbMessageLength
                ? user?.lastMessage?.substring(0, maxdbMessageLength - 3) +
                  "..."
                : user?.lastMessage;
            lastMsgReadByDb =
              user?.LastMsgReadBy && user?.LastMsgReadBy !== currentUser.id
                ? true
                : false;
            const timestamp = parseInt(user?.lastChatTime, 10);
            lastChatTimeDB = formatDateMsg(timestamp);
          }

          const unreadCount = recentMessages.filter(
            (msg) => msg?.senderId === user?.id && !msg.isRead
          ).length;

          let isChatBlurred = blurredChats.includes(user.id);
          let nameChar = user?.userId?.charAt(0).toUpperCase();

          return (
            <div
              className={`CustomerMessages ${
                isChatBlurred || lastMsgReadBy || lastMsgReadByDb
                  ? "setbusy"
                  : "setfree"
              } ${currentChat === user.id ? "active" : ""}`}
              onClick={() => {
                handleOpenChat(user);
                loadSelectedUserMessages(user?.id, lastMsg);
              }}
              key={i}
            >
              <div className="divOne">
                {nameChar == undefined ? "NA" : nameChar}
              </div>
              <div className="divTwo">
                <div className="upperDiv">
                  <h6 style={{ fontFamily: "Roboto" }}>
                    {user?.userId || user?.id?.slice(0, 8)}
                    {user?.isSessionExpired == false &&
                      currentUser?.id !== user?.connectedTo && (
                        <span style={{ color: "red" }}>(busy)</span>
                      )}
                  </h6>
                  {!isChatBlurred && (lastChatTime || lastChatTimeDB) && (
                    <p>{lastChatTime || lastChatTimeDB}</p>
                  )}
                </div>
                <div className="lowerDIv">
                  <Blur
                    radius={
                      isChatBlurred || lastMsgReadBy || lastMsgReadByDb
                        ? "5px"
                        : "0"
                    }
                    transition="400ms"
                  >
                    <p>{lastMsg || lastMsgdb}</p>
                  </Blur>
                  {currentChat !== user?.id && !isChatBlurred &&  
                    (unreadCount > 0 || user?.UnReadMsgCount > 0) && (
                      <div className="msgCount">
                        <div className="msgRec">
                          {unreadCount || user?.UnReadMsgCount}
                        </div>
                      </div>
                    )}

                  <img
                    src={
                      favList?.includes(user)
                        ? require("../../../assets/icons/StarFilled.svg").default
                        : require("../../../assets/icons/StartEmpty.svg").default
                    }
                    alt="user"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleFav(user);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default FavoritesUsers;